import { Tab } from 'bootstrap';

document.addEventListener('turbolinks:load', () => {
  const triggerTabList = [].slice.call(document.querySelectorAll('#pillsTab a'));

  triggerTabList.forEach((triggerEl) => {
    const tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener('click', (event) => {
      event.preventDefault();
      tabTrigger.show();
    });
  });
});
